/**
** JAVASCRIPTS
** Name: Lang
********************************************************************************/

(function() {
  'use strict';

  var $html = document.querySelector('html');

  if(document.querySelectorAll('.js-lang').length > 0) {


    /**
    ** Click
    ****************************************/

    document.addEventListener('click', (event)=>{
      var $target = false;

      if(event.target.classList.contains('js-lang-button')) {
        $target = event.target.closest('.js-lang');
      }
      else if(event.target.closest('.js-lang-button')) {
        $target = event.target.closest('.js-lang');
      }

      if($target) {
        $target.classList.toggle('is-active');

        document.querySelectorAll('.js-navigation-item.menu-active').forEach(function($item) {
          $item.classList.remove('menu-active');
        });

        if($html.classList.contains('submenu-active')) {
          $html.classList.remove('submenu-active');
        }
      }
      else {
        document.querySelectorAll('.js-lang.is-active').forEach(function($lang) {
          $lang.classList.remove('is-active');
        });
      }

    });


  }



})();
