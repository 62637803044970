/**
** JAVASCRIPTS
** Name: Overview
********************************************************************************/

(function() {
  'use strict';

  if(document.querySelector('.js-overview')) {


    /**
    ** Variables
    ****************************************/

    const $overview    = document.querySelector('.js-overview');
    const $controllers = $overview.querySelectorAll('.js-overview-controller');
    const $profiles    = $overview.querySelectorAll('.js-overview-profile');


    /**
    ** Click
    ****************************************/

    $controllers.forEach(function($controller) {
      const index    = $controller.dataset.index;
      const $profile = $overview.querySelector('.js-overview-profile[data-index="' + index + '"]');

      $controller.addEventListener('click', function(event) {
        event.preventDefault();

        if(!$profile.classList.contains('is-active')) {
          const $currentProfile = $overview.querySelector('.js-overview-profile.is-active');
          const currentIndex = $currentProfile.dataset.index;

          $currentProfile.classList.remove('is-active');
          $overview.querySelector('.js-overview-controller[data-index="' + currentIndex + '"]').classList.add('c-button--secondary');

          $profile.classList.add('is-active');
          $controller.classList.remove('c-button--secondary');
        }
      });
    });


  }



})();
