/**
** JAVASCRIPTS
** Name: Logos
********************************************************************************/


/**
** Imports
****************************************/

import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';


/**
** Function
****************************************/

(function() {
  'use strict';

  if(document.querySelectorAll('.js-logos').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $blocks = document.querySelectorAll('.js-logos');


    /**
    ** Init
    ****************************************/

    $blocks.forEach(function($block) {
      const swiper = new Swiper($block.querySelector('.js-logos-container'), {
        modules: [Autoplay],
        a11y: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        slidesPerView: 2,
        speed: 800,
        breakpoints: {
          720: {
            slidesPerView: 3
          },
          960: {
            slidesPerView: 4
          },
          1280: {
            slidesPerView: 5
          }
        }
      });
    });


  }



})();
