/**
** JAVASCRIPTS
** Name: Navigation
********************************************************************************/

(function() {
  'use strict';

  if(document.querySelector('.js-navigation')) {


    /**
    ** Variables
    ****************************************/

    const $html       = document.querySelector('html');
    const $navigation = document.querySelector('.js-navigation');
    const $items      = document.querySelectorAll('.js-navigation-item');


    /**
    ** Click
    ****************************************/

    $items.forEach(function($item) {
      const $link = $item.querySelector('.js-navigation-link');
      const $back = $item.querySelector('.js-navigation-back');

      $link.addEventListener('click', function(event) {
        event.preventDefault();

        if($item.classList.contains('menu-active')) {
          $item.classList.remove('menu-active');
          $html.classList.remove('submenu-active');
        }
        else {
          if(document.querySelectorAll('.js-navigation-item.menu-active').length > 0) {
            document.querySelectorAll('.js-navigation-item.menu-active').forEach(function($activeItem) {
              $activeItem.classList.remove('menu-active');
            });
          }
          $item.classList.add('menu-active');
          $html.classList.add('submenu-active');
        }

        document.querySelectorAll('.js-lang.is-active').forEach(function($lang) {
          $lang.classList.remove('is-active');
        });
      });

      $back.addEventListener('click', function(event) {
        event.preventDefault();
        $item.classList.remove('menu-active');
      });
    });


  }



})();
