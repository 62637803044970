/**
** JAVASCRIPTS
** Name: Quotes
********************************************************************************/


/**
** Imports
****************************************/

import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';


/**
** Function
****************************************/

(function() {
  'use strict';

  if(document.querySelectorAll('.js-quotes').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $blocks = document.querySelectorAll('.js-quotes');


    /**
    ** Init
    ****************************************/

    $blocks.forEach(function($block) {
      const swiper = new Swiper($block.querySelector('.js-quotes-container'), {
        modules: [Navigation],
        a11y: true,
        slidesPerView: 1,
        speed: 800,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        },
      });
    });


  }



})();
