/**
** JAVASCRIPTS
** Name: Header
********************************************************************************/

(function() {
  'use strict';

  if(document.querySelector('.js-header')) {


    /**
    ** Variables
    ****************************************/

    const $html   = document.querySelector('html');
    const $header = document.querySelector('.js-header');
    const $burger = document.querySelector('.js-header-burger');
    const $nav    = document.querySelector('.js-header-navigation');

    var offsetTop     = 1;
    var lastScrollPos = 0;
    var ticking       = false;


    /**
    ** Functions
    ****************************************/


    let height = $header.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${height}px`);

    function setHeaderState(scrollPos, direction) {

      if(scrollPos >= offsetTop) {
        $html.classList.add('header-fixed');
      }
      else {
        $html.classList.remove('header-fixed');
      }

      if(direction > 0) {
        $html.classList.add('header-hidden');
      }
      else {
        $html.classList.remove('header-hidden');
      }

    }

    /**
    ** Resize
    ****************************************/

    window.addEventListener('resize', ()=>{
      let height = $header.offsetHeight;
      document.documentElement.style.setProperty('--header-height', `${height}px`);
    })

    /**
    ** Click
    ****************************************/

    $burger.addEventListener('click', function(event) {
      event.preventDefault();

      $html.classList.toggle('menu-active');

      if(document.querySelectorAll('.js-navigation-item.menu-active').length > 0) {
        document.querySelectorAll('.js-navigation-item.menu-active').forEach(function($item) {
          $item.classList.remove('menu-active');
        });
        $html.classList.remove('submenu-active');
      }
    });


    /**
    ** Scroll
    ****************************************/

    window.addEventListener('scroll', ()=>{
      var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
      var direction = scrollPos > lastScrollPos ? 1 : -1;

      if (!ticking) {
        window.requestAnimationFrame(function() {
          setHeaderState(scrollPos, direction);
          ticking = false;
        });
      }

      lastScrollPos = (scrollPos <= 0) ? 0 : scrollPos;
      ticking = true;
    });


  }



})();
