/**
** JAVASCRIPTS
** Name: KEYS
********************************************************************************/

(function() {
  'use strict';

  if(document.querySelector('.js-keys')) {


    /**
    ** Variables
    ****************************************/

    const $keys   = document.querySelector('.js-keys');
    const $parent = document.querySelector('.js-keys-items');
    const $items  = $keys.querySelectorAll('.js-keys-item');


    /**
    ** Functions
    ****************************************/

    function animateValue(obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.innerHTML = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }

    function startKeys() {
      $items.forEach(function($item) {
        const end = $item.innerHTML;
        const duration = Math.min(Math.max((1000 * (end / 50)), 600), 1500);
        animateValue($item, 0, end, duration);
      });
    }


    /**
    ** Events
    ****************************************/

    window.addEventListener('scroll', ()=>{
      if($parent.classList.contains('is-waiting') && Math.round(window.scrollY + window.innerHeight) > ($parent.getBoundingClientRect().top + window.scrollY)) {
        $parent.classList.remove('is-waiting');
        startKeys();
      }
    });

  }

})();
