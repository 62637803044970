/**
** JAVASCRIPTS
** Name: Folds
********************************************************************************/

(function() {
  'use strict';

  if(document.querySelector('.js-folds')) {


    /**
    ** Variables
    ****************************************/

    const $parent  = document.querySelector('.js-folds');
    const $details = $parent.querySelectorAll('.js-folds-details');


    /**
    ** Click
    ****************************************/

    $details.forEach(function($detail) {
      const $summary = $detail.querySelector('.js-folds-summary');

      $summary.addEventListener('click', function(event) {
        event.preventDefault();

        if(!$detail.open) {
          if($parent.querySelectorAll('.js-folds-details[open]').length > 0) {
            $parent.querySelectorAll('.js-folds-details[open]').forEach(function($openedDetail) {
              $openedDetail.open = false;
            });
          }
          $detail.open = true;
        }
      });
    });


  }



})();
